
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta } from "/home/circleci/project/pages/compare.vue?macro=true";
import { default as indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta } from "/home/circleci/project/pages/paylink/index.vue?macro=true";
import { default as indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta } from "/home/circleci/project/pages/paylink/[id]/index.vue?macro=true";
import { default as affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta } from "/home/circleci/project/pages/paylink/[id]/affirm.vue?macro=true";
import { default as paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta } from "/home/circleci/project/pages/paylink/[id]/payment.vue?macro=true";
import { default as paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta } from "/home/circleci/project/pages/paylink.vue?macro=true";
import { default as cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta } from "/home/circleci/project/pages/checkout/cart.vue?macro=true";
import { default as indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta } from "/home/circleci/project/pages/checkout/index.vue?macro=true";
import { default as addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta } from "/home/circleci/project/pages/checkout/address.vue?macro=true";
import { default as paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta } from "/home/circleci/project/pages/checkout/payment.vue?macro=true";
import { default as _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta } from "/home/circleci/project/pages/checkout/receipt/[id].vue?macro=true";
import { default as checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta } from "/home/circleci/project/pages/checkout.vue?macro=true";
import { default as _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta } from "/home/circleci/project/pages/[...slug].vue?macro=true";
import { default as indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta } from "/home/circleci/project/pages/pay/index.vue?macro=true";
import { default as thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta } from "/home/circleci/project/pages/thank-you.vue?macro=true";
import { default as indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta } from "/home/circleci/project/pages/brands/index.vue?macro=true";
import { default as restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta } from "/home/circleci/project/pages/restore-cart.vue?macro=true";
import { default as indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta } from "/home/circleci/project/pages/account/index.vue?macro=true";
import { default as indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta } from "/home/circleci/project/pages/catalog/index.vue?macro=true";
import { default as indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta } from "/home/circleci/project/pages/product/index.vue?macro=true";
import { default as indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta } from "/home/circleci/project/pages/dealer-locator/index.vue?macro=true";
import { default as internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta } from "/home/circleci/project/pages/dealer-locator/international.vue?macro=true";
import { default as dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta } from "/home/circleci/project/pages/dealer-locator.vue?macro=true";
import { default as indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta } from "/home/circleci/project/pages/sku-lookup/index.vue?macro=true";
import { default as indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta } from "/home/circleci/project/pages/sales-contact/index.vue?macro=true";
import { default as caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta } from "/home/circleci/project/pages/third-party/caltrend.vue?macro=true";
import { default as AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta } from "/home/circleci/project/pages/catalog/AlgoliaVersion.vue?macro=true";
import { default as newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta } from "/home/circleci/project/pages/newsletter-unsubscribe.vue?macro=true";
import { default as _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta } from "/home/circleci/project/pages/order/status/[orderId].vue?macro=true";
import { default as ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta } from "/home/circleci/project/pages/catalog/ConstructorVersion.vue?macro=true";
import { default as configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta } from "/home/circleci/project/pages/third-party/configure-tech.vue?macro=true";
import { default as _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta } from "/home/circleci/project/pages/order/tracking/[trackingId].vue?macro=true";
import { default as indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta } from "/home/circleci/project/pages/installation-instructions/index.vue?macro=true";
import { default as _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta } from "@/pages/[...slug].vue?macro=true";
import { default as cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta } from "/home/circleci/project/integrations/affirm/pages/cancelled.vue?macro=true";
import { default as successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta } from "/home/circleci/project/integrations/affirm/pages/success.vue?macro=true";
import { default as indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta } from "/home/circleci/project/integrations/protex/pages/index.vue?macro=true";
export default [
  {
    name: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta?.name ?? "compare",
    path: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta?.path ?? "/compare",
    props: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta?.props ?? false,
    meta: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta || {},
    alias: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta?.alias || [],
    redirect: compare83fdGsjohQZJY23noGdTvSoBqoL9QniEw06nq2mSIzsMeta?.redirect,
    component: () => import("/home/circleci/project/pages/compare.vue")
  },
  {
    name: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta?.name ?? undefined,
    path: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta?.path ?? "/paylink",
    props: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta?.props ?? false,
    meta: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta || {},
    alias: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta?.alias || [],
    redirect: paylinkRdtWwQ6jHON4IAQjuQ4dioTuztLRYNEGQyW7ScCwtBYMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink.vue"),
    children: [
  {
    name: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta?.name ?? "paylink",
    path: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta?.path ?? "",
    props: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta?.props ?? false,
    meta: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta || {},
    alias: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta?.alias || [],
    redirect: indexkeco4U97x8IomxjEMINnEiVtDSBvxNNNxMld0MM6UZQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/index.vue")
  },
  {
    name: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta?.name ?? "paylink-id",
    path: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta?.path ?? ":id()",
    props: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta?.props ?? false,
    meta: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta || {},
    alias: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta?.alias || [],
    redirect: indexqx41JSLHVUL4Vi_mqQJ7dvOKcfxa4bN7d4DNkXex5uUMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/index.vue")
  },
  {
    name: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta?.name ?? "paylink-id-affirm",
    path: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta?.path ?? ":id()/affirm",
    props: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta?.props ?? false,
    meta: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta || {},
    alias: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta?.alias || [],
    redirect: affirmejxz9sRoUm227LzUcWaCcXJ6wpDVRE8LBdQe70g8feYMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/affirm.vue")
  },
  {
    name: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta?.name ?? "paylink-id-payment",
    path: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta?.path ?? ":id()/payment",
    props: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta?.props ?? false,
    meta: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta || {},
    alias: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta?.alias || [],
    redirect: paymentz3i5Gp2mgsu4k38OZiLTA7szwxW9Q1cwPiDPlyvC7KMMeta?.redirect,
    component: () => import("/home/circleci/project/pages/paylink/[id]/payment.vue")
  }
]
  },
  {
    name: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta?.name ?? undefined,
    path: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta?.path ?? "/checkout",
    props: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta?.props ?? false,
    meta: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta || {},
    alias: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta?.alias || [],
    redirect: checkoutchhngvqJ_h15sFiWNTvI6rxW_3AnyGJ6ePS_45kJ6dT70Meta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout.vue"),
    children: [
  {
    name: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta?.name ?? "checkout-cart",
    path: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta?.path ?? "cart",
    props: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta?.props ?? false,
    meta: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta || {},
    alias: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta?.alias || [],
    redirect: cartCy2dAGjMH4ohpZ16KBSSg6BBOsKjSIh_45w6U0ztGIBHQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/cart.vue")
  },
  {
    name: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta?.name ?? "checkout",
    path: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta?.path ?? "",
    props: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta?.props ?? false,
    meta: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta || {},
    alias: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta?.alias || [],
    redirect: indexOs_vjTebPy_45sc78ZwIwTLuFMoJkb1NFNdyCtvFJasPUMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/index.vue")
  },
  {
    name: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta?.name ?? "checkout-address",
    path: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta?.path ?? "address",
    props: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta?.props ?? false,
    meta: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta || {},
    alias: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta?.alias || [],
    redirect: addressv4sqCvy1kJn529b3XMhU4Ie672yMSSsdNN97BEpjsQgMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/address.vue")
  },
  {
    name: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta?.name ?? "checkout-payment",
    path: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta?.path ?? "payment",
    props: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta?.props ?? false,
    meta: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta || {},
    alias: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta?.alias || [],
    redirect: paymentRUfKtZPaQKeFj7HRtNMRegDoov0S7AnezatMAzAMLooMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/payment.vue")
  },
  {
    name: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta?.name ?? "checkout-receipt-id",
    path: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta?.path ?? "receipt/:id()",
    props: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta?.props ?? false,
    meta: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta || {},
    alias: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta?.alias || [],
    redirect: _91id_93r_45bd0dq5nGq_tgIsX_45lEvu3GzKF_45ae0BBNxB15G_45MEAMeta?.redirect,
    component: () => import("/home/circleci/project/pages/checkout/receipt/[id].vue")
  }
]
  },
  {
    name: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta?.name ?? "slug",
    path: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta?.path ?? "/:slug(.*)*",
    props: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta?.props ?? false,
    meta: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta || {},
    alias: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta?.alias || [],
    redirect: _91_46_46_46slug_93VGeg3VnRoDdroOdQ2bBCR_45g4uZmqG0dMU46RN3p2azQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/[...slug].vue")
  },
  {
    name: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta?.name ?? "pay",
    path: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta?.path ?? "/pay",
    props: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta?.props ?? false,
    meta: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta || {},
    alias: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta?.alias || [],
    redirect: indexaDlgvz4_45Icbatu3XyPsPjllde7Hb2zsZuA7IYFapMCQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/pay/index.vue")
  },
  {
    name: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta?.name ?? "thank-you",
    path: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta?.path ?? "/thank-you",
    props: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta?.props ?? false,
    meta: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta || {},
    alias: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta?.alias || [],
    redirect: thank_45youv2plijus8h16EmY0uZrMueAj0JGs8wQQmtth3UDFJpoMeta?.redirect,
    component: () => import("/home/circleci/project/pages/thank-you.vue")
  },
  {
    name: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta?.name ?? "brands",
    path: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta?.path ?? "/brands",
    props: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta?.props ?? false,
    meta: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta || {},
    alias: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta?.alias || [],
    redirect: indexAm30d_455C64AqrRR_v42GipH4e6xILB1_Znp5AUtiLYYMeta?.redirect,
    component: () => import("/home/circleci/project/pages/brands/index.vue")
  },
  {
    name: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta?.name ?? "restore-cart",
    path: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta?.path ?? "/restore-cart",
    props: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta?.props ?? false,
    meta: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta || {},
    alias: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta?.alias || [],
    redirect: restore_45cartwHBWOx1_09CzBxpUp98sWlNVMy6bDfauZNDPoAUtZOsMeta?.redirect,
    component: () => import("/home/circleci/project/pages/restore-cart.vue")
  },
  {
    name: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta?.name ?? "account",
    path: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta?.path ?? "/account",
    props: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta?.props ?? false,
    meta: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta || {},
    alias: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta?.alias || [],
    redirect: indexu7XJrYHuSviSFTOehHgkIwKWGe7qBGUgji8UEyRmh1IMeta?.redirect,
    component: () => import("/home/circleci/project/pages/account/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "brand",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/b/:brandSlug",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "brand-mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/b/:brandSlug/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "category-custom",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/f/:facetSlug",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "category-custom-mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/f/:facetSlug/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "category",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "category-mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/c/:lvl0/:lvl1?/:lvl2?/:lvl3?/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "new-products",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/new-products",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "new-products-mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/new-products/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "specials",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/specials",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "specials-mmy",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/specials/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: { ...(indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {}), ...{"isFitable":true} },
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.name ?? "product",
    path: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.path ?? "/p/:productLine",
    props: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.props ?? false,
    meta: { ...(indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta || {}), ...{"isFitable":true} },
    alias: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.alias || [],
    redirect: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.name ?? "product-mmy",
    path: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.path ?? "/p/:productLine/v/:makeSlug/:modelSlug?/:year(\\d{4})?",
    props: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.props ?? false,
    meta: { ...(indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta || {}), ...{"isFitable":true} },
    alias: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.alias || [],
    redirect: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.name ?? "sku",
    path: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.path ?? "/p/:productLine/:skuSlug",
    props: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.props ?? false,
    meta: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta || {},
    alias: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.alias || [],
    redirect: indexLFWrYZX7lyHUWCaM_gOXOxAT3jxrW1Ldl0jIZErP_8EMeta?.redirect,
    component: () => import("/home/circleci/project/pages/product/index.vue")
  },
  {
    name: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta?.name ?? undefined,
    path: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta?.path ?? "/dealer-locator",
    props: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta?.props ?? false,
    meta: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta || {},
    alias: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta?.alias || [],
    redirect: dealer_45locatorKegZBw5ObZgzxV6v1RfMeBxJj1oyB4uFOOTIvE2quNcMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator.vue"),
    children: [
  {
    name: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta?.name ?? "dealer-locator",
    path: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta?.path ?? "",
    props: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta?.props ?? false,
    meta: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta || {},
    alias: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta?.alias || [],
    redirect: indexsVEDvvpqY86RKir_45ms5vML_8ZgRGK1ktF_45ceCt4Sb2oMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator/index.vue")
  },
  {
    name: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta?.name ?? "dealer-locator-international",
    path: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta?.path ?? "international",
    props: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta?.props ?? false,
    meta: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta || {},
    alias: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta?.alias || [],
    redirect: internationalV_YqV0aTaxNDNgt7JiYZG39cNW3Ed6ZEM_45_eLw_45qiEgMeta?.redirect,
    component: () => import("/home/circleci/project/pages/dealer-locator/international.vue")
  }
]
  },
  {
    name: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta?.name ?? "sku-lookup",
    path: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta?.path ?? "/sku-lookup",
    props: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta?.props ?? false,
    meta: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta || {},
    alias: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta?.alias || [],
    redirect: indexJjZX_I9uNjKDLL0vgmz6sbl40DCNVO_45CM49bsLFT9GQMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sku-lookup/index.vue")
  },
  {
    name: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta?.name ?? "sales-contact",
    path: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta?.path ?? "/sales-contact",
    props: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta?.props ?? false,
    meta: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta || {},
    alias: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta?.alias || [],
    redirect: indexu9EdTM3dShSjqFc2psSnItoJQLmJAqgq6Dz2EL_kQuYMeta?.redirect,
    component: () => import("/home/circleci/project/pages/sales-contact/index.vue")
  },
  {
    name: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta?.name ?? "third-party-caltrend",
    path: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta?.path ?? "/third-party/caltrend",
    props: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta?.props ?? false,
    meta: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta || {},
    alias: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta?.alias || [],
    redirect: caltrendkq7T87VvBcl980KA6ZxuhSrUOPvnG3u38wqEUGmDiPkMeta?.redirect,
    component: () => import("/home/circleci/project/pages/third-party/caltrend.vue")
  },
  {
    name: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta?.name ?? "catalog-AlgoliaVersion",
    path: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta?.path ?? "/catalog/AlgoliaVersion",
    props: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta?.props ?? false,
    meta: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta || {},
    alias: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta?.alias || [],
    redirect: AlgoliaVersionJ251va5NQVt_Fulx6Bg_cCamRiL_IQHoFgDMDVXLCZgMeta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/AlgoliaVersion.vue")
  },
  {
    name: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta?.name ?? "newsletter-unsubscribe",
    path: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta?.path ?? "/newsletter-unsubscribe",
    props: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta?.props ?? false,
    meta: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta || {},
    alias: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta?.alias || [],
    redirect: newsletter_45unsubscribewL16dXJshl_45hopZvpfj085X6vVD1Cmua2vs3IXdV08QMeta?.redirect,
    component: () => import("/home/circleci/project/pages/newsletter-unsubscribe.vue")
  },
  {
    name: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta?.name ?? "order-status-orderId",
    path: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta?.path ?? "/order/status/:orderId()",
    props: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta?.props ?? false,
    meta: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta || {},
    alias: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta?.alias || [],
    redirect: _91orderId_93FS0T0ISCS5PP1jSZCH6KsbDHjChN8eRT2YE4q9htcqcMeta?.redirect,
    component: () => import("/home/circleci/project/pages/order/status/[orderId].vue")
  },
  {
    name: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta?.name ?? "catalog-ConstructorVersion",
    path: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta?.path ?? "/catalog/ConstructorVersion",
    props: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta?.props ?? false,
    meta: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta || {},
    alias: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta?.alias || [],
    redirect: ConstructorVersionwTVjyj0ByuDmnooEsqiNeO5WwvC7k6IsoO0cFs_MMu0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/ConstructorVersion.vue")
  },
  {
    name: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta?.name ?? "third-party-configure-tech",
    path: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta?.path ?? "/third-party/configure-tech",
    props: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta?.props ?? false,
    meta: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta || {},
    alias: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta?.alias || [],
    redirect: configure_45techAC5APv8VsOdUGy_45RXG88JGsv1FoR94fh0Oc3JWQKpacMeta?.redirect,
    component: () => import("/home/circleci/project/pages/third-party/configure-tech.vue")
  },
  {
    name: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta?.name ?? "order-tracking-trackingId",
    path: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta?.path ?? "/order/tracking/:trackingId()",
    props: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta?.props ?? false,
    meta: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta || {},
    alias: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta?.alias || [],
    redirect: _91trackingId_93i0TfNXRy4J_UMk17oiJWlaHeQsavEcoFI9NUX_7P6MUMeta?.redirect,
    component: () => import("/home/circleci/project/pages/order/tracking/[trackingId].vue")
  },
  {
    name: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta?.name ?? "installation-instructions",
    path: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta?.path ?? "/installation-instructions",
    props: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta?.props ?? false,
    meta: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta || {},
    alias: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta?.alias || [],
    redirect: indexy_e5I5PRagcnp_45bSMQWv_45bGs6SsEOh7EiPbmCbEiKWIMeta?.redirect,
    component: () => import("/home/circleci/project/pages/installation-instructions/index.vue")
  },
  {
    name: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta?.name ?? "home",
    path: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta?.path ?? "/",
    props: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta?.props ?? false,
    meta: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta || {},
    alias: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta?.alias || [],
    redirect: _91_46_46_46slug_93hA4RpLr7yHw88d0I5_45is4Hu56z_459BxdEGtxjvoZXcg4Meta?.redirect,
    component: () => import("@/pages/[...slug].vue")
  },
  {
    name: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.name ?? "search",
    path: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.path ?? "/search",
    props: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.props ?? false,
    meta: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta || {},
    alias: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.alias || [],
    redirect: indexd2pODQKuVA6g_45dUBrLrNltmsKJP91bB8dbMIv_TLsZ0Meta?.redirect,
    component: () => import("/home/circleci/project/pages/catalog/index.vue")
  },
  {
    name: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta?.name ?? "affirm-cancelled",
    path: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta?.path ?? "/affirm/cancelled",
    props: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta?.props ?? false,
    meta: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta || {},
    alias: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta?.alias || [],
    redirect: cancelledxfGqrUOuEr_45dAF_1rZS1YUN1O6OTorlWSSzKSpbSMz4Meta?.redirect,
    component: () => import("/home/circleci/project/integrations/affirm/pages/cancelled.vue")
  },
  {
    name: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta?.name ?? "affirm-success",
    path: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta?.path ?? "/affirm/success",
    props: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta?.props ?? false,
    meta: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta || {},
    alias: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta?.alias || [],
    redirect: successulTnv4E7Cf6HWKg_cnTNC2K1OTNCImu2Zx3gzT4U_45okMeta?.redirect,
    component: () => import("/home/circleci/project/integrations/affirm/pages/success.vue")
  },
  {
    name: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta?.name ?? "protex-index",
    path: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta?.path ?? "/protex/index",
    props: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta?.props ?? false,
    meta: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta || {},
    alias: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta?.alias || [],
    redirect: indexICt6n_WCITxEjZnKM_YOH3Rv2xBt4mJEwTOlJslWR6UMeta?.redirect,
    component: () => import("/home/circleci/project/integrations/protex/pages/index.vue")
  }
]