<template lang="pug">
div(class='flex-1')
  ConstructorioABTest
    template(#original)
      CmsWidgetSiteSearchAlgolia(:data='data' :search-icon-color='iconColorClass')

    template(#constructor)
      CmsWidgetSiteSearchConstructor(:search-text='data.searchText' :search-icon-color='iconColorClass')
</template>

<script setup lang="ts">
interface SiteSearchWidget extends Widget {
  searchText: string
  showCategoriesInSearch: boolean
  showBrandsInSearch: boolean
  searchRedirectsEnabled: boolean
  searchIconColor: 'BLACK' | 'WHITE'
}

const { data } = defineProps<{
  data: SiteSearchWidget
}>()

// Tailwind Whitelist
// [&_path]:fill-white
// [&_path]:fill-black
const iconColorClass = computed(() => {
  if (data.searchIconColor) return `[&_path]:fill-${data.searchIconColor.toLowerCase()}`
  return '[&_path]:fill-black'
})
</script>
