export default function wireProductListeners() {
  const segment = useSegmentHelper()
  const { getCategoryTreeFromCrumbs, getFullFitment } = useSegmentProductsHelper()
  const { $uiEvents } = useNuxtApp()

  $uiEvents.$on('BuilderButtonViewed', (productData: ProductLine) => {
    segment.track('Realtruck Builder Button Viewed', formatTrackingInfo(productData))
  })
  $uiEvents.$on('BuilderButtonClicked', (productData: ProductLine) => {
    segment.track('Realtruck Builder Button Clicked', formatTrackingInfo(productData))
  })

  function formatTrackingInfo(productData: ProductLine) {
    const price = productData.actualPrice?.min ? productData.actualPrice.min / 100 : 0
    return {
      productLineSlug: productData.productLineSlug,
      productName: productData.name,
      price,
      category: getCategoryTreeFromCrumbs(productData.crumbs),
      fitment: getFullFitment(),
    }
  }
}
