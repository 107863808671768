// https://dev.to/razi91/event-bus-with-vue-3-and-typescript-2a6l
// https://github.com/developit/mitt/issues/163
// This should only be used for true page/action -> marketing events or true global events.  For page specific junk, I'd think they'd use their own emitter with provide/inject.
// Should we have multiple buses?  1 for true marketing junk (TrackingBus/BeaconBus/MarketingBus) and 1 for global UI events which run the app (GlobalUIBus)?

import type { RouteLocationNormalized } from '#vue-router'
import mitt from 'mitt'

const emitter = mitt<MittEvent>()

export default defineNuxtPlugin(() => {
  const router = useRouter()
  const route = useRoute()

  // not sure if we really need the delay, but it's consistent with below
  // using an obj for the event for easy upgrades later, using to vs { route } really so consumers can use the same listeners for initial hits & route changes
  setTimeout(() => {
    emitter.emit('firstLoaded', route)
    checkProductHit(route)
  }, 1000)

  router.afterEach((to, from) => {
    // if you are coming to, from a route with a query string, just ignore.. we are concerned with path changes
    // this would fire for /c/mud-flaps/ > /c/mud-flaps/chevy/silverado-1500/2010/
    if (to.path === from.path) return

    // At the time this is called (afterEach) the title and url are still the old ones.. Wait :(
    // https://github.com/nuxt/vue-meta/issues/259
    // https://github.com/vuejs/vue-router/issues/1197
    setTimeout(() => {
      emitter.emit('routeChanged', { to, from })
      checkProductHit(to, from)
    }, 1000)
  })

  // On the product page, mounted() and beforeRouteUpdate() get called multiple times and when you don't expect them (compared to plain Vue SPA)
  // Therefore, we are processing plain page hits here and telling the rest of integrations about them.  Here, we ignore changes in YMM too.
  function checkProductHit(to: RouteLocationNormalized, from?: RouteLocationNormalized) {
    if (to.params.productLine && (to.params.productLine !== from?.params.productLine || from?.params.skuSlug))
      emitter.emit('productLineViewed') // is this still needed with Nuxt 3?  Also, this doesn't belong here.
  }

  return {
    provide: {
      uiEvents: {
        $on: emitter.on,
        $off: emitter.off,
        $emit: emitter.emit,
      },
    },
  }
})

type MittEvent = Record<EventKey, any>
type EventKey =
  | 'addShippingProtection'
  | 'addToCart'
  | 'addWarranty'
  | 'amplitudeExposure'
  | 'backInStockSubscribed'
  | 'checkoutStarted'
  | 'cmsLinkClicked'
  | 'cognitoSignIn'
  | 'cognitoSignUp'
  | 'dealerLocatorDealerViewed'
  | 'dealerLocatorDirectionsClicked'
  | 'dealerLocatorPhoneClicked'
  | 'dealerLocatorProductLinkClicked'
  | 'dealerLocatorProductViewed'
  | 'dealerLocatorProductDealerClicked'
  | 'dealerLocatorWebsiteClicked'
  | 'doesNotFitViewed'
  | 'emailSet'
  | 'errorLog'
  | 'facetClicked'
  | 'firstLoaded'
  | 'fitmentChanged'
  | 'giftCardAdded'
  | 'giftCardDenied'
  | 'giftCardRemoved'
  | 'installationAdded'
  | 'installationRemoved'
  | 'installernetOffered'
  | 'navigationClicked'
  | 'newsletterFormSubmitted'
  | 'newsletterSubscribed'
  | 'newsletterSubscribedSms'
  | 'newsletterUnSubscribed'
  | 'noResultsViewed'
  | 'noSearchResults'
  | 'productCheckoutStarted'
  | 'paymentInfoEntered'
  | 'checkoutStepViewed'
  | 'checkoutStepCompleted'
  | 'orderPlaced'
  | 'paylinkSent'
  | 'phonePaymentPlaced'
  | 'productLineViewed'
  | 'productListClicked'
  | 'productListFiltered'
  | 'productListViewed'
  | 'productShippingViewed'
  | 'productViewed'
  | 'promoCodeApplied'
  | 'promoCodeDenied'
  | 'promoCodeEntered'
  | 'promoCodeRemoved'
  | 'removeShippingProtection'
  | 'removeFromCart'
  | 'addGroupsToCart'
  | 'BuilderButtonViewed'
  | 'BuilderButtonClicked'
  | 'removeWarranty'
  | 'routeChanged'
  | 'saveForLaterAdded'
  | 'saveForLaterDeleted'
  | 'saveForLaterMoved'
  | 'searched'
  | 'warrantyDeclined'
  | 'warrantyOfferMade'
  | 'cartViewed'
  | 'constructorProductListViewed'
  | 'constructorProductListFiltered'
  | 'constructorProductListSorted'
  | 'constructorProductClicked'
