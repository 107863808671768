export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const fitmentStore = useFitmentStore()

  // === PRODUCTS === //

  const formatCartProduct = (product: any, position = 1) => {
    if (!product) return {}
    const obj = {
      name: product.productName,
      sku: product.sku,
      position,
      quantity: product.qty,
      category: product.category,
      // brand - waiting on back end
      year: product.fitmentData?.year,
      make: product.fitmentData?.makeName,
      model: product.fitmentData?.modelName,
      price: product.salePrice / 100,
      product_id: product.productSlug, // cart also has skuSlug, but receipt obj does not :(
      isEligibleFreeShipping: product.isEligibleFreeShipping,
      shipMethod: product.shipMethod,
      shipDate: product.shipDate,
      estimatedDeliveryDate: product.estimatedDeliveryDate,
      edd_min: product.estimatedDeliveryDate,
      ...(product.installation?.subtotal && {
        installation_price: product.installation.subtotal,
      }),
      quickship: $sitewideConfig.config.sameDayShippingEnabled && product.shipMethod === 'EXPEDITED',
    }

    if (product.productLineSlug)
      obj.url = `https://${$sitewideConfig.domain}/p/${product.productLineSlug}/${product.productSlug}/`

    // If we have a warranty then add it to the obj
    if (product.warranty?.id) {
      const warrantyArray = Array.isArray(product.warranty) ? product.warranty : [product.warranty]

      obj.warranty = warrantyArray.map((warranty: any) => ({
        sku: warranty.id,
        price: warranty.price / 100,
      }))
    }

    return obj
  }

  // Data coming in from the Product Store
  const formatProductData = (product: any) => {
    const primaryFitment = getFitment()
    const category = getCategoryTreeFromCrumbs(product.crumbs)

    return {
      name: product.name,
      ...(product.sku && { sku: product.sku }),
      ...(product.skuSlug && { product_id: product.skuSlug }),
      quantity: 1,
      ...(category && { category: category.slugs.join('/') }),
      brand: product.brand.name,
      ...(product.actualPrice && { price: product.actualPrice.min / 100 }),
      currency: 'usd',
      ...(product.actualPrice && { value: product.actualPrice.min / 100 }), // Total value of the product after quantity
      isOutOfStock: product.isOutOfStock,
      ...(primaryFitment && primaryFitment),
    }
  }

  // Used for category products
  const isQuickship = (shippingData: any) => {
    if (!$sitewideConfig.config.sameDayShippingEnabled || !shippingData) return null
    return !!shippingData.sameDay || shippingData.sameDayShipping
  }

  const getFitment = () => {
    const year = fitmentStore.year
    const make = fitmentStore.makeSlug
    const model = fitmentStore.modelSlug

    return {
      ...(year && { year: year }),
      ...(make && { make: make }),
      ...(model && { model: model }),
    }
  }

  const getFullFitment = () => {
    const year = fitmentStore.year
    const make = fitmentStore.makeSlug
    const model = fitmentStore.modelSlug
    const bed = fitmentStore.bedSlug
    const body = fitmentStore.bodySlug
    const engine = fitmentStore.engineSlug

    return {
      ...(year && { year: year }),
      ...(make && { make: make }),
      ...(model && { model: model }),
      ...(bed && { bed: bed }),
      ...(body && { body: body }),
      ...(engine && { engine: engine }),
    }
  }

  // since we don't have a 'real' category tree obj, we'll have to leverage crumbs :(
  // note: this may contain different category verbiage than real life (UWP = Folding vs classic RT = Folding Tonneau Covers)
  const getCategoryTreeFromCrumbs = (crumbs: any) => {
    if (!crumbs || crumbs.length < 2) return // should at least have home & pline node

    const names = []
    const slugs = []
    // skip home & last node (pline)
    for (let i = 1; i < crumbs.length - 1; i++) {
      names.push(crumbs[i].title)
      slugs.push(crumbs[i].urlData.params[`lvl${i - 1}`])
    }

    return {
      names,
      slugs,
    }
  }

  // Used by emitCart() and checkout
  // loops through an array of cart products and returns them
  const getCartProducts = (products: any) => {
    return products.map((product: any, index: number) => {
      return formatCartProduct(product, index + 1)
    })
  }

  return {
    formatCartProduct,
    formatProductData,
    getFitment,
    getFullFitment,
    getCategoryTreeFromCrumbs,
    getCartProducts,
    isQuickship,
  }
}
